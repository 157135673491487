import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { Auth0Provider } from "@auth0/auth0-react";
import authConfig from "./auth_config.json";

// const onRedirectCallback = (appState) => {
//   window.history.replaceState(
//     {},
//     document.title,
//     appState && appState.returnTo
//       ? appState.returnTo
//       : window.location.pathname
//   );
// };

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <Auth0Provider
        domain={`${authConfig.domain}`}
        clientId={`${authConfig.clientId}`}
        authorizationParams={{
            redirect_uri: `${window.location.origin}/chat`,
            audience: `${authConfig.audience}`,
            scope: "openid profile email"
        }}
    >
        <App />
    </Auth0Provider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
