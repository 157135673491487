import React from "react";
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import { Container, ContentWithPaddingXl } from "components/misc/Layouts";
import tw from "twin.macro";
import styled from "styled-components";
import { css } from "styled-components/macro"; //eslint-disable-line
import Header from "components/headers/light.js";
import Footer from "components/footers/FiveColumnWithBackground.js";
import { SectionHeading } from "components/misc/Headings";
import GlobalStyles from 'styles/GlobalStyles';
import ReactMarkdown from 'react-markdown';
import remarkGfm from 'remark-gfm';

const HeadingRow = tw.div`flex`;
const Heading = tw(SectionHeading)`text-gray-900 mb-10`;
const Text = styled.div`
  ${tw`text-lg  text-gray-800`}
  p {
    ${tw`mt-2 leading-loose`}
  }
  h1 {
    ${tw`text-3xl font-bold mt-10`}
  }
  h2 {
    ${tw`text-2xl font-bold mt-8`}
  }
  h3 {
    ${tw`text-xl font-bold mt-6`}
  }
  ul {
    ${tw`list-disc list-inside`}
    li {
      ${tw`ml-2 mb-3`}
      p {
        ${tw`mt-0 inline leading-normal`}
      }
    }
  }
`;

// IMPORTANT NOTE: There is some magic happening bellow that when the privacy policy text is indented too much it won't render in markdown
const termsOfService = `
# Terms of Use
*Last Updated: December 05, 2024*

Coruscant (the "Company") makes available to you our website, available at yestherapy.chat, and various online products and services, which include our AI Therapist service (collectively, the "Services"). By accessing or using the Services, you agree to comply with and be bound to these Terms of Use (these "Terms"). If you do not agree to these Terms, you may not use the Services.

## 1. Use of the Service

The Services are provided for general fitness, health and wellness purposes only. The Services do not include the provision of medical care, mental health services or other professional services, and do not diagnose, cure, treat, mitigate or prevent diseases or conditions.

You must be 18 years of age (or such other minimum age at which you can provide consent to data processing under the laws of your territory) or older to use the Services.

You are granted a limited, non-exclusive, revocable and non-assignable license to use the Services for personal, non-commercial use only. We may terminate or suspend your access to the Services at any time and for any reason, including for your breach of these Terms. We also may terminate your account if it has been inactive for 12 months. If we do, we will provide you with advance notice.

You are responsible for paying any fees that we indicate are applicable to your use of the Services.

## 2. Not a Substitute for Professional Therapy

The Services do not function as, and are not a substitute for, professional therapy, counseling, diagnosis, or treatment, and do not provide medical advice. The Services do not respond to emergencies; if you are experiencing a crisis, severe mental health issues, or thoughts of harming yourself or others, please seek immediate help from a licensed mental health professional. In case of emergency, please call 911(or your local emergency number), and consider using the following resources:

- Call or text 988 to talk to a free, confidential counselor from the Suicide & Crisis Lifeline, (available 24/7 and in Spanish), or visit www.988lifeline.org;
- Contact 1-800-799-7233 to reach the National Domestic Violence hotline, or visit www.thehotline.org/help.

## 3. User Responsibilities

By using the Services, you agree to:

- Use the Services in accordance with these Terms and applicable laws and regulations.
- Not use the Service for any unlawful or harmful purpose.
- Not share or transmit any content that is defamatory, obscene, abusive, invasive of privacy, or otherwise objectionable or in violation of applicable laws and regulations.

## 4. Ownership and Intellectual Property

All content and materials available by the Services, including but not limited to text, graphics, website name, code, images, and logos are the intellectual property of Company or its licensors and are protected by applicable copyright and trademark law. You may not modify, publish, transmit, participate in the transfer or sale of, reproduce, creative derivative works based on, or otherwise exploit any of the Services.

If you believe that your intellectual property rights have been infringed, please send notice to the address below or fill out this form. We may delete or disable content that we believe violates these Terms or is alleged to be infringing and will terminate accounts of repeat infringers where appropriate.

*Coruscant, Delaware Address*

Written claims concerning copyright infringement must include the following information:

- A physical or electronic signature of the person authorized to act on behalf of the owner of the copyright interest
- A description of the copyrighted work that you claim has been infringed upon
- A description of where the allegedly infringing material is located on our site so we can find it
- Your address, telephone number, and e-mail address
- A statement by you that you have a good-faith belief that the disputed use is not authorized by the copyright owner, its agent, or the law
- A statement by you that the above information in your notice is accurate and, under penalty of perjury, that you are the copyright owner or authorized to act on the copyright owner's behalf

## 5. Data Collection and Use

We collect, use, and safeguard your information as described in our Privacy Policy. By using the Services, you consent to the collection and use of your data in accordance with the Privacy Policy. Company retains ownership of all data collected through your interactions with the Service, as well as any derivative data, including synthetic data generated for improving the Services.

## 6. Disclaimer of Warranties

The Services are provided "as is." Except to the extent prohibited by law, Company makes no warranties (express, implied, statutory or otherwise) with respect to the Services, and disclaims all warranties including, but not limited to, warranties of merchantability, fitness for a particular purpose, satisfactory quality, non-infringement, and quiet enjoyment, and any warranties arising out of any course of dealing or trade usage. Company does not warrant that the Services will be uninterrupted, accurate or error free, or that any content will be secure or not lost or altered.

## 7. Limitation of Liability

To the fullest extent permitted by law, Company shall not be liable for any indirect, incidental, special, consequential, or punitive damages, including but not limited to loss of profits, data, or other intangible losses, resulting from your use of the Service. Without limiting the foregoing, Company's liability to you in no event shall exceed the greater of (a) $50 and (b) the aggregate amount of fees paid by you to Company in the preceding twelve months.

## 8. Indemnification

You agree to indemnify, defend, and hold harmless Company, its officers, directors, employees, agents, and affiliates from and against any claims, liabilities, damages, losses, and expenses, including reasonable attorney's fees and costs, arising out of or in any way connected with your access to or use of the Services or your breach of these Terms.

## 9. Changes to Terms

Company reserves the right to modify these Terms at any time. We will notify you of any changes by posting the new Terms on this page. You are advised to review these Terms periodically for any changes. Changes to these Terms are effective when they are posted on this page.

## 10. Governing Law; Disputes

These Terms shall be governed and construed in accordance with the laws of the state of New York/California, without regard to its conflict of law provisions. Any disputes arising under or in connection with these Terms or the Services shall be subject to the exclusive jurisdiction of the courts located in New York, New York/San Francisco, CA. YOU AGREE THAT BY AGREEING TO THESE TERMS, YOU WAIVE THE RIGHT PARTICIPATE AS A PLAINTIFF OR CLASS MEMBER IN ANY PURPORTED CLASS ACTION OR REPRESENTATIVE PROCEEDING.

## 11. No Waiver; Severability

Company's failure to enforce a provision is not a waiver of its right to do so later. If any portion of these Terms is determined to be invalid or unenforceable, that portion will be enforced to the maximum extent permissible and it will not affect the enforceability of any other terms.
`


export default ({ headingText = "Terms And Condition" }) => {
  return (
    <><GlobalStyles />
      <AnimationRevealPage>
        <Header />
        <Container>
          <ContentWithPaddingXl>
            <HeadingRow>
              <Heading>{headingText}</Heading>
            </HeadingRow>
            <div className="markdown">
              <ReactMarkdown remarkPlugins={[remarkGfm]}>{termsOfService}</ReactMarkdown>
            </div>
          </ContentWithPaddingXl>
        </Container>
        <Footer />
      </AnimationRevealPage>
    </>
  );
};
