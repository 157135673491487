import React from "react";
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import { Container, ContentWithPaddingXl } from "components/misc/Layouts";
import tw from "twin.macro";
import styled from "styled-components";
import { css } from "styled-components/macro"; //eslint-disable-line
import Header from "components/headers/light.js";
import Footer from "components/footers/FiveColumnWithBackground.js";
import { SectionHeading } from "components/misc/Headings";
import GlobalStyles from 'styles/GlobalStyles';
import ReactMarkdown from 'react-markdown';
import remarkGfm from 'remark-gfm';

const HeadingRow = tw.div`flex`;
const Heading = tw(SectionHeading)`text-gray-900 mb-10`;
const Text = styled.div`
  ${tw`text-lg  text-gray-800`}
  p {
    ${tw`mt-2 leading-loose`}
  }
  h1 {
    ${tw`text-3xl font-bold mt-10`}
  }
  h2 {
    ${tw`text-2xl font-bold mt-8`}
  }
  h3 {
    ${tw`text-xl font-bold mt-6`}
  }
  ul {
    ${tw`list-disc list-inside`}
    li {
      ${tw`ml-2 mb-3`}
      p {
        ${tw`mt-0 inline leading-normal`}
      }
    }
  }
`;

// IMPORTANT NOTE: There is some magic happening bellow that when the privacy policy text is indented too much it won't render in markdown
const privacyPolicy = `
  *Last Updated: December 05, 2024*

  This Privacy Policy explains how Coruscant (the "Company") collects, uses, discloses, and safeguards your information when you use our website, available at yestherapy.chat, and various online products and services, which include our AI Therapist service (collectively, the "Services"). We respect your privacy and are committed to keeping secure any information we collect from you or about you. By using the Services, you agree to this policy.

  ## 1. Information We Collect

  We collect the following types of information:

  - **Personal Information**: If you create an account with us, we collect personal information that identifies you personally, such as your name, email address, and any other details you provide.
  - **Service Data**: When you use the Services, we collect information such as the content of your interactions with the Service, conversations with the AI therapist chatbot, including text, voice recordings and/or transcriptions, images, and other media.
  - **Technical and Usage Data**: We collect (i) information that your browser or device automatically sends when you use the Services, including IP address, browser type and settings, date and time, and other information, (ii) information about how you engage with the Services, such as date and time of access, type of computer or device, operating system and device identifiers, log data and location information, and (iii) we use cookies and similar tracking technologies to track the activity on our Services and hold certain information.
  - **Information We Receive from Other Sources**: We receive information from our trusted partners, such as security partners, to protect against fraud, abuse, and other security threats to the Services, and from marketing vendors who provide us with information about potential customers of our business services.
  - **Other Information You Provide**: Other information that you may provide to us, such as when you participate in our events or surveys.

  We may de-identify and/or aggregate certain of your information, like approximate location information, information about the device you use to access the Service, information about conversational trends, or other analyses we create. De-identified and/or aggregated information is not personal information and we may use and share this information as permitted by applicable law.

  ## 2. How We Use Your Information

  We use the information we collect for various purposes, including to:

  - Provide, maintain, and improve the Services.
  - Personalize your experience with the Services.
  - Analyze usage patterns and user interactions to enhance the Services' effectiveness.
  - Communicate with you, respond to your inquiries, and provide support.
  - Prevent fraud, illegal activity, or misuse of the Services, and to protect the security of our system.
  - Develop and train future AI models using the data collected and synthetic data derived from it.

  ## 3. Disclosure of your Information

  We do not sell or share your information with third parties, except in the following circumstances:

  - **Service Providers**: We may share information with third-party service providers who perform services on our behalf, such as data storage and processing, provided they agree to adhere to our privacy standards.
  - **Legal Requirements**: We may disclose your information if required to do so by law, or in response to valid requests by public authorities (e.g., a court or a government agency).
  - **Protection of Rights**: We may disclose your information to protect the rights, property, or safety of the Company, our users, or others.
  - **Business Transfers**: If we are involved in strategic transactions, reorganization, bankruptcy, receivership, or transition of service to another provider (collectively, a "Transaction"), your information may be disclosed in the diligence process with counterparties and others assisting with the Transaction and transferred to a successor or affiliate as part of that Transaction along with other assets.

  ## 4. Retention of your Information

  We'll retain your information for only as long as needed to provide the Service to you, or for other legitimate business purposes such as resolving disputes, safety and security, or complying with our legal obligations. How long we retain Personal Data will depend on a number of factors, such as:

  - How you are using the Service, and which parts, of the Service you are using, in order to provide the Service to you;
  - The amount, nature, and sensitivity of the information;
  - Any legal requirements that we are subject to.

  ## 5. Your Rights

  Anyone who uses the Services can access, correct, or delete their personal information regardless of where they live or are physically located, and can opt-out of receiving communications from us by following the unsubscribe instructions provided in the emails or contacting us directly. In addition, under certain data protection laws, individuals may have rights around their personal information which may include (as applicable):

  - The right of access to the personal information we have collected and to receive a copy of this personal data.
  - The right to correct any incorrect personal information we have collected.
  - The right to delete personal information we have retained, subject to specific exceptions.
  - The right to limit the use and disclosure of certain sensitive personal information.

  We will process your request in accordance with applicable laws. If you wish to exercise any of the above rights, please Contact Us.

  ## 6. Third-Party Links

  The Services may contain links to third-party websites or services that are not owned or controlled by us. We have no control over, and assume no responsibility for, privacy policies or practices of any third-party websites or services.

  ## 7. Children's Privacy

  Our Service is not intended for anyone under the age of 13 ("Children"). We do not knowingly collect personally identifiable information from Children under 13.

  ## 8. International Data Transfers

  Your information, including Personal Information, may be transferred to — and maintained on — computers located outside of your state, province, country, or other governmental jurisdiction where the data protection laws may differ from those of your jurisdiction.

  ## 9. Changes to This Privacy Policy

  Company reserves the right to modify this Privacy Policy at any time. We will notify you of any changes by posting the new Privacy Policy on this page. You are advised to review this Privacy Policy periodically for any changes. Changes to this Privacy Policy are effective when they are posted on this page.

  ## 10. Ownership of Data

  Company retains ownership of all data collected through your interactions with the Service as set forth in our Terms of Use.

  ## 11. Data Security

  We implement appropriate technical and organizational measures to protect your data against unauthorized access, loss, misuse, or alteration. However, no method of transmission over the internet or method of electronic storage is 100% secure. While we strive to use commercially acceptable means to protect your data, we cannot guarantee its absolute security.
  `

export default ({ headingText = "Privacy Policy" }) => {
  return (
    <><GlobalStyles />
      <AnimationRevealPage>
        <Header />
        <Container>
          <ContentWithPaddingXl>
            <HeadingRow>
              <Heading>{headingText}</Heading>
            </HeadingRow>
            <div className="markdown">
              <ReactMarkdown remarkPlugins={[remarkGfm]}>{privacyPolicy}</ReactMarkdown>
            </div>
          </ContentWithPaddingXl>
        </Container>
        <Footer />
      </AnimationRevealPage>
    </>
  );
};
