export function getRandomQuestions(count) {
  const shuffled = clientQuestions.sort(() => Math.random() - 0.5);
  return shuffled.slice(0, count);
}

const clientQuestions = [
  "How can I better understand what I’m feeling?",
  "What’s a good way to start dealing with my stress?",
  "How can I improve my communication with others?",
  "Why do I keep repeating certain patterns?",
  "How can I handle this specific situation better?",
  "What’s a healthier way to set boundaries?",
  "How can I stop feeling so overwhelmed?",
  "What’s a good way to deal with my fear of failure?",
  "How do I start working on my self-esteem?",
  "What’s the first step to processing my emotions?",
  "How do I know if I’m making progress?",
  "What’s a good way to start healing from my past?",
  "How can I stop feeling so stuck?",
  "What’s a realistic way to set goals for myself?",
  "How do I approach forgiveness—of myself or others?",
  "How can I learn to trust people again?",
  "What’s a healthy way to cope with loss?",
  "How can I improve my relationship with myself?",
  "How do I stop overthinking everything?",
  "What’s the best way to build resilience?",
  "How can I manage my anxiety better?",
  "Why do I struggle so much with change?",
  "How can I stop feeling so guilty all the time?",
  "What’s a good way to practice self-compassion?",
  "How can I recognize what I truly want?",
  "How do I know if I’m being too hard on myself?",
  "How can I find a balance between work and life?",
  "What’s a productive way to deal with anger?",
  "How can I better manage my time and priorities?",
  "What’s a healthier way to deal with conflict?",
  "How can I rebuild my confidence after a setback?",
  "What’s a better way to handle criticism?",
  "How can I start making peace with my past?",
  "How can I feel more in control of my life?",
  "What’s the best way to stay motivated when things feel hard?"
];
