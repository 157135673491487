import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Chat from './Chat.js';
import SaaSProductLandingPage from "demos/SaaSProductLandingPage.js";
import TermsOfServicePage from "pages/TermsOfService.js";
import PrivacyPolicyPage from "pages/PrivacyPolicy.js";
// import AboutUsPage from "pages/AboutUs.js";

function App() {
  return (
    <>
      <Router>
        <Routes>
          {/* Default Route */}
          <Route path="/chat" element={<Chat />} />
          {/* Route for difLink */}
          <Route path="/" element={<SaaSProductLandingPage />} />

          <Route path="/terms-of-service" element={<TermsOfServicePage />} />
          <Route path="/privacy-policy" element={<PrivacyPolicyPage />} />
          {/* <Route path="/about" element={<AboutUsPage />} /> */}

        </Routes>
      </Router>
    </>
  );
}

export default App;
