import React from "react";
import tw from "twin.macro";
// import { css } from "styled-components/macro"; //eslint-disable-line
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import Hero from "components/hero/TwoColumnWithInput.js";
import Features from "components/features/ThreeColWithSideImage.js";
// import MainFeature from "components/features/TwoColWithButton.js";
// import MainFeature2 from "components/features/TwoColWithTwoHorizontalFeaturesAndButton.js";
// import FeatureWithSteps from "components/features/TwoColWithSteps.js";
// import Pricing from "components/pricing/ThreePlans.js";
import Testimonial from "components/testimonials/TwoColumnWithImageAndRating.js";
import FAQ from "components/faqs/SingleCol.js";
import GetStarted from "components/cta/GetStarted";
import Footer from "components/footers/FiveColumnWithBackground.js";
// import heroScreenshotImageSrc from "images/hero-screenshot-1.png";
// import macHeroScreenshotImageSrc from "images/hero-screenshot-2.png";
// import prototypeIllustrationImageSrc from "images/prototype-illustration.svg";
// import { ReactComponent as BriefcaseIcon } from "feather-icons/dist/icons/briefcase.svg";
// import { ReactComponent as MoneyIcon } from "feather-icons/dist/icons/dollar-sign.svg";
import GlobalStyles from 'styles/GlobalStyles';
// import styled from "styled-components";
import guy from "../pics/guy.png";



export default () => {
  const Subheading = tw.span`uppercase tracking-widest font-bold text-primary-500`;
  const HighlightedText = tw.span`text-primary-500`;

  return (
    <><GlobalStyles />
      <AnimationRevealPage>
        <Hero roundedHeaderButton={true} />
        <Testimonial
          subheading={<Subheading>We are on a MISSION</Subheading>}
          heading={
            <>
              World Class Therapy <HighlightedText>For Free</HighlightedText>
            </>
          }
          description="We are on an unstoppable mission to revolutionize mental health with the power of AI therapy. Our goal is bold but simple: deliver world-class therapy to everyone, everywhere for free."
          testimonials={[
            {
              stars: 5,
              profileImageSrc:
                "https://images.unsplash.com/photo-1494790108377-be9c29b29330?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=3.25&w=512&h=512&q=80",
              heading: "Incredible Insights and Analysis",
              quote:
                "An absolute game changer. The amount of insight I get into myself and others from the Yes Therapy chat has been incredible. It has analyzed and broken down complex social situations and even personal patterns.",
              customerName: "Beta User",
              customerTitle: "November 2024"
            },
            {
              stars: 5,
              profileImageSrc:
                guy,
              heading: "We're collecting more testimonials!",
              quote:
                "Email me at the bottom Contact Us link if you'd like to share your Yes Therapy story!",
              customerName: "Could be You",
              customerTitle: "Soon..."
            }
          ]}
        />
        <Features
          subheading={<Subheading>Features</Subheading>}
          heading={
            <>
              Highly Trained <HighlightedText>AI Therapists</HighlightedText>
            </>
          }
        />
        {/* <MainFeature2
          subheading={<Subheading>We are on a MISSION</Subheading>}
          heading={
            <>
              Join the AI Therapy <HighlightedText>Revolution</HighlightedText>
            </>
          }
          imageSrc={prototypeIllustrationImageSrc}
          description="We are on an unstoppable mission to revolutionize mental health with the power of AI therapy. Our goal is bold but simple: deliver world-class therapy to everyone, everywhere for free."
          showDecoratorBlob={false}
          primaryButtonUrl="/chat"
          primaryButtonText="Chat Now"
          features={[
            {
              Icon: MoneyIcon,
              title: "Free",
              description: "World class therapy should be free for everyone always.",
              iconContainerCss: tw`bg-green-300 text-green-800`
            },
            {
              Icon: BriefcaseIcon,
              title: "Highest Standards",
              description: "Uncompromising standards, ensuring exceptional quality care for everyone, everywhere.",
              iconContainerCss: tw`bg-red-300 text-red-800`
            }
          ]}
        /> */}

        <FAQ
          subheading={<Subheading>FAQS</Subheading>}
          heading={
            <>
              Frequently Asked <HighlightedText>Questions</HighlightedText>
            </>
          }
          description="AI Therapy is a brand new industry with promise to provide free, accessible, and private mental health support to everyone."
          faqs={[
            {
              question: "What is an AI therapist, and how does it work?",
              answer:
                "An AI therapist is a highly intelligent AI model meant to be a mental health tool that provides supportive, empathetic, and constructive conversations. It analyzes your input to offer personalized advice, coping strategies, and resources for personal growth."
            },
            {
              "question": "Is the AI therapist really free to use?",
              "answer": "Yes! Our mission is to make mental health care accessible to everyone, so the AI therapist is completely free with no hidden costs. We do have plans add premium features in the future in order to sustain the free version for everyone."
            },
            {
              "question": "How private and secure are my conversations with the AI?",
              "answer": "Your privacy and security are our top priorities you have the option to have your therapist know you and so it can follow up or you can choose temporary chats where no conversation data is stored. Please see our privacy policy at the bottom of the page for more details."
            },
            {
              "question": "Is the AI therapist available in different languages?",
              "answer": "Yes, the AI therapist is designed to support all major languages, making it accessible to a diverse global audience. Simply start typing in your language, and the AI will adapt to communicate with you effectively."
            },
            {
              "question": "Will my information be shared with anyone?",
              "answer": "No, your conversations are completely confidential and temporary chats are never stored. Please see our privacy policy at the bottom of the page for more details."
            },
            // {
            //   "question": "Can the AI therapist diagnose mental health conditions?",
            //   "answer": "No, the AI therapist is not a replacement for a licensed mental health professional and cannot provide diagnoses. It is designed to offer support, coping tools, and guidance while encouraging you to seek professional help if needed."
            // },
            // {
            //   "question": "Is the AI therapist available 24/7?",
            //   "answer": "Absolutely! The AI therapist is always ready to chat whenever you need support, day or night."
            // },
            // {
            //   "question": "Can the AI therapist help with anxiety, stress, or depression?",
            //   "answer": "Yes, the AI is equipped to provide tools and techniques for managing a variety of challenges, including anxiety, stress, and feelings of depression. However, it’s important to seek professional help for severe or persistent issues."
            // },
            // {
            //   "question": "What makes this different from speaking with a human therapist?",
            //   "answer": "While a human therapist provides professional expertise, empathy, and deeper guidance, the AI therapist offers instant support, 24/7 availability, and zero cost. It’s a complementary tool for your mental health journey, not a replacement."
            // },
            // {
            //   "question": "How can I get started with the AI therapist?",
            //   "answer": "Getting started is simple! Just click on the blue 'Chat' button in the top right corner, and you’ll enter your first therapy session."
            // }
          ]}
        />
        <GetStarted />
        <Footer />
      </AnimationRevealPage>
    </>
  );
}


{/* <MainFeature
        subheading={<Subheading>Quality Work</Subheading>}
        imageSrc={heroScreenshotImageSrc}
        imageBorder={true}
        imageDecoratorBlob={true}
      /> */}
{/* <FeatureWithSteps
        subheading={<Subheading>STEPS</Subheading>}
        heading={
          <>
            Easy to <HighlightedText>Get Started.</HighlightedText>
          </>
        }
        textOnLeft={false}
        imageSrc={macHeroScreenshotImageSrc}
        imageDecoratorBlob={true}
        decoratorBlobCss={tw`xl:w-40 xl:h-40 opacity-15 -translate-x-1/2 left-1/2`}
      /> */}

{/* <Pricing
        subheading={<Subheading>Pricing</Subheading>}
        heading={
          <>
            Reasonable & Flexible <HighlightedText>Plans.</HighlightedText>
          </>
        }
        plans={[
          {
            name: "Personal",
            price: "$17.99",
            duration: "Monthly",
            mainFeature: "For Individuals",
            features: ["30 Templates", "7 Landing Pages", "12 Internal Pages", "Basic Assistance"]
          },
          {
            name: "Business",
            price: "$37.99",
            duration: "Monthly",
            mainFeature: "For Small Businesses",
            features: ["60 Templates", "15 Landing Pages", "22 Internal Pages", "Priority Assistance"],
            featured: true
          },
          {
            name: "Enterprise",
            price: "$57.99",
            duration: "Monthly",
            mainFeature: "For Large Companies",
            features: ["90 Templates", "27 Landing Pages", "37 Internal Pages", "Personal Assistance"]
          }
        ]}
      /> */}




// <Testimonial
//     subheading={<Subheading>Testimonials</Subheading>}
//     heading={
//       <>
//         Our Clients <HighlightedText>Love Us.</HighlightedText>
//       </>
//     }
//     testimonials={[
//       {
//         stars: 5,
//         profileImageSrc:
//           "https://images.unsplash.com/photo-1494790108377-be9c29b29330?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=3.25&w=512&h=512&q=80",
//         heading: "Amazing User Experience",
//         quote:
//           "Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco.",
//         customerName: "Charlotte Hale",
//         customerTitle: "Director, Delos Inc."
//       },
//       {
//         stars: 5,
//         profileImageSrc:
//           "https://images.unsplash.com/photo-1531427186611-ecfd6d936c79?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2.25&w=512&h=512&q=80",
//         heading: "Love the Developer Experience and Design Principles !",
//         quote:
//           "Sinor Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam.",
//         customerName: "Adam Cuppy",
//         customerTitle: "Founder, EventsNYC"
//       }
//     ]}
//   />