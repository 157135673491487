import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import './App.css';
import ReactMarkdown from 'react-markdown';
import remarkGfm from 'remark-gfm';
import { FaMicrophone } from 'react-icons/fa';
import { getRandomQuestions } from './antiAwkwardStarts';
// import { therapists } from './Therapists';
import { ReactComponent as GearIcon } from "./gear.svg";
import { ReactComponent as ArrowIcon } from "./arrow2.svg";
import { ReactComponent as IncognitoIcon } from "./incognito.svg";
import { useAuth0 } from "@auth0/auth0-react";

function Chat() {
  // const [selectedTherapist, setSelectedTherapist] = useState(therapists[0]);
  // Start with one empty conversation in memory
  const [conversations, setConversations] = useState([{
    id: null,
    title: '',
    messages: []
  }]);
  const [currentConversationId, setCurrentConversationId] = useState(null);
  const [inputText, setInputText] = useState('');
  const [isLoadingResp, setIsLoadingResp] = useState(false);
  const [isResponding, setIsResponding] = useState(false);
  const [theme, setTheme] = useState('light');
  const [recording, setRecording] = useState(false);
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);
  const [isTemporaryChat, setIsTemporaryChat] = useState(false);

  const [isSettingsOpen, setIsSettingsOpen] = useState(false);
  const [answerLength, setAnswerLength] = useState('regular');

  const [inputPassword, setInputPassword] = useState('');
  const [passwordCorrect, setPasswordCorrect] = useState(true);
  const correctPassword = 'password1234';

  const chatWindowRef = useRef(null);
  const inputRef = useRef(null);
  const mediaRecorderRef = useRef(null);
  const audioChunksRef = useRef([]);
  const streamRef = useRef(null);
  const recordingTimeoutRef = useRef(null);

  const MAX_RECORDING_TIME = 60;

  const predefinedMessages = getRandomQuestions(5);
  // const [suggestions, setSuggestions] = useState(predefinedMessages);
  const disabledTooltip = "This can only be changed before the chat starts.";

  const { loginWithRedirect, logout, isAuthenticated, getAccessTokenSilently, isLoading } = useAuth0();

  const currentConversation = conversations.find(
    (conv) => conv.id === currentConversationId
  ) || conversations[0]; // fallback to the first conversation if none selected

  const messages = currentConversation.messages || [];
  const isConversationStarted = messages.length > 0;

  const toggleTheme = () => {
    setTheme((prevTheme) => (prevTheme === 'light' ? 'dark' : 'light'));
  };

  const handleSend = async (message = inputText) => {
    if (!message.trim()) return;

    const userMessage = { sender: 'user', text: message };
    const updatedMessages = [...messages, userMessage];

    let updatedConversations = conversations.map((conv) => {
      if (conv.id === currentConversationId) {
        let updatedConv = { ...conv, messages: updatedMessages };
        // If no title yet and this is the first user message in this conversation, set it
        if (!updatedConv.title || updatedConv.title === '') {
          updatedConv.title = message.slice(0, 20) + (message.length > 20 ? '...' : '');
        }
        return updatedConv;
      }
      return conv;
    });

    // If this is a new conversation with no ID, update the first conversation (which is new)
    if (currentConversationId === null) {
      let updatedConv = { ...conversations[0], messages: updatedMessages };
      if (!updatedConv.title || updatedConv.title === '') {
        updatedConv.title = message.slice(0, 20) + (message.length > 20 ? '...' : '');
      }
      updatedConversations = [updatedConv, ...conversations.slice(1)];
    }

    setConversations(updatedConversations);
    setInputText('');
    setIsLoadingResp(true);
    setIsResponding(true);

    try {
      const fullMessages = [...messages, userMessage];
      const conversation_history = fullMessages.map((msg) => ({
        role: msg.sender === 'user' ? 'user' : 'assistant',
        content: msg.text,
      }));

      let requestBody = {
        message,
        model: "claude",
        therapistName: "X2",
        conversationId: currentConversationId,
        newConvo: messages.length === 0,
        answerLength: answerLength,
        isTemporaryChat: isTemporaryChat,
      };

      requestBody.conversation_history = conversation_history;
      const token = await getAccessTokenSilently();

      const response = await axios.post('/api/chat', requestBody, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      });

      const aiText = response.data.aiText;

      // Update conversationId if the server created a new one
      let updatedConvoId = currentConversationId;
      if (!currentConversationId && response.data.conversationId) {
        updatedConvoId = response.data.conversationId;
        setCurrentConversationId(updatedConvoId);
        setConversations((prev) =>
          prev.map((conv) => {
            if (conv.id === null) {
              return { ...conv, id: updatedConvoId };
            }
            return conv;
          })
        );
      }

      displayAIResponse(aiText, updatedConvoId || currentConversationId);
    } catch (error) {
      console.error(error);
      if (error.response) {
        console.error('Error Response:', error.response.data);
        alert(
          `Error ${error.response.status}: ${error.response.data.error.message || error.response.data.error}`
        );
      } else {
        alert('An unexpected error occurred.');
      }
      setIsResponding(false);
    } finally {
      setIsLoadingResp(false);
    }
  };

  const displayAIResponse = (text, convoId) => {
    // const { mainText, questions } = extractQuestions(text);

    // if (questions.length > 0) {
    //   setSuggestions(questions);
    // }

    let index = 0;
    const words = text.split(' ');
    const aiMessage = { sender: 'ai', text: '' };

    setConversations((prevConversations) =>
      prevConversations.map((conv) =>
        conv.id === convoId
          ? { ...conv, messages: [...conv.messages, aiMessage] }
          : conv
      )
    );

    const interval = setInterval(() => {
      if (index < words.length) {
        aiMessage.text += (index > 0 ? ' ' : '') + words[index];

        setConversations((prevConversations) =>
          prevConversations.map((conv) => {
            if (conv.id === convoId) {
              const updatedMessages = [...conv.messages.slice(0, -1), { ...aiMessage }];
              return { ...conv, messages: updatedMessages };
            }
            return conv;
          })
        );

        index++;
      } else {
        clearInterval(interval);
        setIsResponding(false);
      }
    }, 40);
  };

  const handlePredefinedMessage = (msg) => {
    if (isResponding) return;
    handleSend(msg);
  };

  const startRecording = async () => {
    try {
      audioChunksRef.current = [];
      const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
      streamRef.current = stream;

      mediaRecorderRef.current = new MediaRecorder(stream);

      mediaRecorderRef.current.ondataavailable = (event) => {
        if (event.data.size > 0) {
          audioChunksRef.current.push(event.data);
        }
      };

      mediaRecorderRef.current.onstop = handleRecordingStop;

      mediaRecorderRef.current.start();
      setRecording(true);

      recordingTimeoutRef.current = setTimeout(() => {
        stopRecording();
      }, MAX_RECORDING_TIME * 1000);
    } catch (error) {
      console.error('Error starting recording:', error);
      alert('Could not access microphone. Please check your browser permissions.');
    }
  };

  const stopRecording = () => {
    if (mediaRecorderRef.current && mediaRecorderRef.current.state !== 'inactive') {
      mediaRecorderRef.current.stop();
    }
    if (streamRef.current) {
      streamRef.current.getTracks().forEach((track) => track.stop());
    }
    setRecording(false);

    if (recordingTimeoutRef.current) {
      clearTimeout(recordingTimeoutRef.current);
    }
  };

  const handleRecordingStop = async () => {
    const audioBlob = new Blob(audioChunksRef.current, { type: 'audio/webm' });
    const formData = new FormData();
    formData.append('file', audioBlob, 'speech.webm');

    try {
      const response = await fetch('/api/transcribe', {
        method: 'POST',
        body: formData,
      });

      const data = await response.json();
      setInputText((prevInputText) => (prevInputText ? prevInputText + ' ' : '') + data.transcription);
    } catch (error) {
      console.error('Error sending audio to server:', error);
      alert('Error transcribing audio. Please try again.');
    }
  };

  const handleMicClick = async () => {
    if (recording) {
      stopRecording();
    } else {
      try {
        await startRecording();
      } catch (error) {
        console.error('Microphone error:', error);
        alert('Could not access microphone. Please check your browser permissions.');
      }
    }
  };

  useEffect(() => {
    const updateVH = () => {
      const vh = window.innerHeight * 0.01;
      document.documentElement.style.setProperty('--vh', `${vh}px`);

      if (chatWindowRef.current) {
        setTimeout(() => {
          chatWindowRef.current.scrollTop = chatWindowRef.current.scrollHeight;
        }, 100);
      }
    };

    updateVH();
    window.addEventListener('resize', updateVH);
    window.addEventListener('orientationchange', () => {
      setTimeout(updateVH, 100);
    });

    return () => {
      window.removeEventListener('resize', updateVH);
      window.removeEventListener('orientationchange', updateVH);
    };
  }, []);

  useEffect(() => {
    if (chatWindowRef.current) {
      chatWindowRef.current.scrollTop = chatWindowRef.current.scrollHeight;
    }
  }, [messages]);

  const handlePassword = (e) => {
    e.preventDefault();
    if (inputPassword === correctPassword) {
      setPasswordCorrect(true);
    } else {
      alert('Incorrect password!');
    }
  };

  const handleNewChat = () => {
    setConversations([{
      id: null,
      title: 'New Conversation',
      messages: []
    }]);
    setCurrentConversationId(null);
    // setSuggestions(predefinedMessages);
    setIsTemporaryChat(false);
  };


  return (
    <div className={`chat-container ${theme}`}>
      <div className={`sidebar ${isSidebarOpen ? 'open' : ''}`}>
        <div className="sidebar-header">
          <h2>Conversations</h2>
          <button onClick={() => setIsSidebarOpen(false)}>×</button>
        </div>
        <button className="new-chat-button" onClick={handleNewChat}>
          + New Chat
        </button>
        <button className={`new-temp-chat-button ${isTemporaryChat ? 'selected' : ''}`} onClick={() => setIsTemporaryChat(!isTemporaryChat)}>
          + Temporary Chat <IncognitoIcon className="incognito-icon" />
        </button>
        {/* <div className="therapist-list">
          <h3>Therapists</h3>
          {therapists.map((therapist) => (
            <div
              key={therapist.id}
              className={`therapist-item ${therapist.id === selectedTherapist.id ? 'active' : ''}`}
              onClick={() => setSelectedTherapist(therapist)}
            >
              <img src={therapist.profilePic} alt={therapist.name} className="therapist-profile-pic" />
              <div>
                <div className="therapist-name">{therapist.name}</div>
                <div className="therapist-description">{therapist.description}</div>
              </div>
            </div>
          ))}
        </div> */}
      </div>
      <div className="main-content">
        <div className="header">
          <button
            className="sidebar-toggle"
            onClick={() => setIsSidebarOpen(!isSidebarOpen)}
          >
            ☰
          </button>
          <h1>Yes Therapy <sup>(Beta)</sup></h1>
          <div className="header-buttons">
            <div className="settings">
              <button
                className="settings-button"
                onClick={() => setIsSettingsOpen((prev) => !prev)}
              >
                <GearIcon className="gear-icon" />
              </button>
              {isSettingsOpen && (
                <div className="settings-dropdown">
                  <div className="settings-option">
                    <button className="theme-toggle" onClick={toggleTheme}>
                      {theme === 'dark' ? 'Light' : 'Dark'}
                    </button>
                    <>
                      {isAuthenticated && (
                        <button onClick={() => logout()}>
                          Log Out
                        </button>
                      )}
                    </>
                  </div>
                  <div className="settings-option">
                    <label>Answer Length:</label>
                    <div className="answer-length-options">
                      <label
                        className={isConversationStarted ? 'disabled-label' : ''}
                        data-tooltip={isConversationStarted ? disabledTooltip : ''}
                      >
                        <input
                          type="radio"
                          name="answerLength"
                          value="short"
                          checked={answerLength === 'short'}
                          onChange={(e) => setAnswerLength(e.target.value)}
                          disabled={isConversationStarted}
                        /> Short
                      </label>
                      <label
                        className={isConversationStarted ? 'disabled-label' : ''}
                        data-tooltip={isConversationStarted ? disabledTooltip : ''}
                      >
                        <input
                          type="radio"
                          name="answerLength"
                          value="regular"
                          checked={answerLength === 'regular'}
                          onChange={(e) => setAnswerLength(e.target.value)}
                          disabled={isConversationStarted}
                        /> Regular
                      </label>
                      <label
                        className={isConversationStarted ? 'disabled-label' : ''}
                        data-tooltip={isConversationStarted ? disabledTooltip : ''}
                      >
                        <input
                          type="radio"
                          name="answerLength"
                          value="verbose"
                          checked={answerLength === 'verbose'}
                          onChange={(e) => setAnswerLength(e.target.value)}
                          disabled={isConversationStarted}
                        /> Verbose
                      </label>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
        <div className="chat-window" ref={chatWindowRef}>
          <div className="notification">
            {isTemporaryChat ? <p><strong>Temporary chat enabled.</strong> No conversations is stored.</p> : <p>Update: Temporary chat's can now be enabled in the top left. The therapist will remember nothing from a temporary session. We love feedback and suggestions!</p>}
          </div>

          {messages.map((msg, idx) => {
            if (msg.sender === 'ai') {
              return (
                <div key={idx} className="message ai">
                  <ReactMarkdown remarkPlugins={[remarkGfm]}>
                    {msg.text}
                  </ReactMarkdown>
                </div>
              )
            } else {
              return (
                <div key={idx} className="message user">
                  {msg.text}
                </div>
              );
            }
          })}

          {isLoadingResp && (
            <div className="typing">
              X2 is typing
              <span className="dot dot1">.</span>
              <span className="dot dot2">.</span>
              <span className="dot dot3">.</span>
            </div>
          )}
        </div>


        {/* // Instead of showing the overlay right away, wait until Auth0 finishes loading */}
        {!isLoading && !isAuthenticated && (
          <div className="auth-overlay">
            <div className="auth-container">
              <h2>Welcome to Yes Therapy</h2>
              <p>Please sign in or create an account to start chatting with the therapist.</p>
              <p>By Signing up, you agree to our <a href="/terms-of-service">Terms of Service</a> and <a href="/privacy-policy">Privacy Policy</a>.</p>
              <button className="auth-button" onClick={() => loginWithRedirect()}>
                Log In / Sign Up
              </button>
            </div>
          </div>
        )}


        {passwordCorrect ? (
          <div>
            <div className="bottom-bar">
              {/* {suggestions.length > 0 && (
                <div className="suggestions-bar">
                  <div className="suggestions-track">
                    {suggestions.concat(suggestions).map((suggestion, index) => (
                      <button
                        key={index}
                        onClick={() => handlePredefinedMessage(suggestion)}
                        className="suggestion-button"
                        disabled={isResponding}
                      >
                        {suggestion}
                      </button>
                    ))}
                  </div>
                </div>
              )} */}

              <div className="input-area">
                <textarea
                  ref={inputRef}
                  value={inputText}
                  onChange={(e) => setInputText(e.target.value)}
                  onKeyPress={(e) => {
                    if (e.key === 'Enter' && !e.shiftKey) {
                      e.preventDefault();
                      handleSend();
                    }
                  }}
                  placeholder="Type your message..."
                />
                <button
                  onClick={handleMicClick}
                  className={`mic-button ${recording ? 'recording' : ''}`}
                  disabled={isResponding}
                >
                  <FaMicrophone size={20} color={recording ? '#FF4E42' : '#fff'} />
                </button>
                <button onClick={() => handleSend()} disabled={isResponding}>
                  <ArrowIcon className="arrow-icon" />
                </button>
              </div>
            </div>
          </div>
        ) : (
          <div className="bottom-bar">
            <div className="input-area">
              <form onSubmit={handlePassword} className="password-form">
                <input
                  type="password"
                  placeholder="Enter Password..."
                  value={inputPassword}
                  onChange={(e) => setInputPassword(e.target.value)}
                  className="password-input"
                />
                <button type="submit" className="password-submit">
                  Submit
                </button>
              </form>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}

export default Chat;
